import styled from "styled-components";
import { Rem } from "../../commons/Theme";

export const StyledButtonBlock = styled.div`
  text-align: center;
  margin: ${Rem(20)} 0 ${Rem(30)};
`;

export const StyledChildren = styled.div`
  // text-align: center;
  width: 100%;
`;
